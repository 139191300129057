import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    wellinfo_search: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.WELLINFO_SEARCH(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    sourcelog_search: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.SOURCELOG_SEARCH(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    depth_nulls: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.DEPTH_NULLS(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    time_nulls_preferred: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.TIME_NULLS_PREFERRED(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    time_nulls_misce: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.TIME_NULLS_MISCE(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    depth_datagaps: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.DEPTH_DATAGAPS(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    time_datagaps: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.TIME_DATAGAPS(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
    trajectory_nulls: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.datagaps.TRAJECTORY_NULLS(),payload) 
            return response
         } catch (error) {
             return error
         }
    },
}