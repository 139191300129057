



export default {
    // async getTagName({ state }, dtls) {
    //     let tagIndex = state.wells[dtls.wellId]["wellbores"][dtls.wellboreId]["logs"][dtls.logType]["tags"].indexOf(dtls.title);

    // },
    getReplaytDetails:state=>state.replayDetails,
    getBooleanValue:state=> state.myBooleanValue,
}