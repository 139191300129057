import rect from './modules/rect';
import data from './modules/data';
import display from './modules/display';
import live from './modules/live';
import uis from './modules/uis';

import { createStore } from "vuex"

const store = createStore({
   modules: { 'rect': rect, 'data': data, 'disp': display, 'live': live,'uis':uis }
})

export default store
