<!-- <template>
    <div class="container ml-auto mr-auto w-3/5 text-white text-2xl h-full overflow auto">

      <div @click="()=> $router.push('/auth/login')" class="absolute __back_icon bg-emerald-600" style="color: var(--textColor); top: 6%; left: 5%; font-size: 160%;"><i class="fa fa-arrow-left" aria-hidden="true"></i></div>

      <h2 class="__privacy_heading">Forget Password</h2>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p class="text-center"></p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        tomail_disabled: true,
        toMail: "support@apollodart.com",
        from: "",
        contact: null,
        subject: "Issue-",
        body: "",
        // attachment: null,
        mail_Sending: false,
        loader: false,
        // capturedImage: null,
        // fileType: null,
        // showAttachmentInfo: false,
        // login_user_as_cc: '',
      };
    },
    components: {
    },
    methods: {

    },
  };
  </script>
  <style scoped>
  .__privacy_heading {
      font-size: 76px;
      line-height: normal;
  }
  .__back_icon{
    border-radius: 50%;
    padding: 1%;
    cursor: pointer;
  }
  </style>
   -->

<template>
  <!-- <div class="container-fluid mx-auto px-0 w-full h-full dark_background"> -->
  <div
    v-if="isVerified"
    style="color: var(--textColor)"
    class="flex content-center items-center justify-center dark_primary h-screen items-center mx-auto"
  >
    <div class="w-full lg:w-4/12 px-4 shadow">
      <div class="w-full px-4" style="background: var(--sidebarbg)">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6">
          <div class="-t mb-0 px-6 py-6">
            <div class="text-center px-4 mb-3">
              <h4 class="dark_text text-2xl">Reset Password</h4>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-200" />
          </div>
          <div class="flex-auto px-4 py-10 pt-0">
            <form>
              <div class="relative w-full mb-3">
                <label class="block uppercase dark_text text-xs font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  v-model="auth.userName"
                  class="ipField border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  readonly
                />
              </div>
              <div class="relative w-full mb-3">
                <label class="block uppercase dark_text text-xs font-bold mb-2"
                  >New Password</label
                >
                <input
                  :type="pass_showHide ? 'text' : 'password'"
                  placeholder="New Password"
                  class="ipField border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input text-xs shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="newpassword"
                />
                <br />
                <span v-if="errMsz.newpassword" class="text-red-500">{{
                  errMsz.newpassword
                }}</span>
                <span
                  class="show_hide icon is-small is-right"
                  @click="toggleEye()"
                >
                  <svg
                    v-show="pass_showHide"
                    class="svg-inline--fa fa-eye fa-w-18"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="eye"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    ></path>
                  </svg>
                  <svg
                    v-show="!pass_showHide"
                    class="svg-inline--fa fa-eye-slash fa-w-20"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="eye-slash"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="text-center mt-6">
                <button :disabled="!isValidPassword" type="button" @click="changepassword() "
                  class="curser bg-green-600 text-white active:bg-green-800 text-sm font-semibold bgClr uppercase px-6 py-3  shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  :class="[
                    validDataAdded ? 'is-success' : 'is-white is-outlined',
                  ]" style="background: var(--activeTextColor)">
                  {{ isLoading ? "Please wait..." : "Reset password" }}
                </button>
              </div>
            </form>
          </div>
          <div class="relative p-6 mt-4 flex-auto w-full">
            <div class="flex justify-end px-4 py-10 pt-0">
              <button
                class="text-xs font-semibold border-b p-0 self-end outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                type="button"
                style="
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
                @click="() => $router.push('/auth/login')"
              >
                <i class="fas fa-long-arrow-alt-left"></i> Go back to login page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <div
    v-else
    style="color: var(--textColor)"
    class="inset-0 z-50 outline-none focus:outline-none justify-center items-center w-full mx-auto flex inset-0 flex items-center justify-center"
  >
    <div class="relative w-full my-4 mx-auto max-w-3xl">
      <!--content-->
      <div
        style="background: var(--navBar2Bgsame)"
        class="w-full lg:w-4/12 mx-auto shadow-lg relative flex flex-col outline-none focus:outline-none justify-center"
      >
        <div
          class="flex items-start justify-center px-4 py-2 border-b border-solid border-blueGray-200 -t"
        >
          <div
            class="md:mt-12 md:ml-20 flex justify-center w-full items-center"
          >
            <p class="text-center tracking-wide text-xl font-bold">
              Reset Password
            </p>
          </div>
        </div>
        <!--body-->
        <div class="relative p-6 mt-4 flex-auto w-full">
          <div class="flex flex-col justify-center px-4 py-10 pt-0">
            <div class="relative mb-3">
              <label class="block uppercase dark_text text-md font-bold mb-2">
                Email
              </label>
              <input
                type="email"
                v-model="auth.userName"
                class="ipField border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="Email"
              />
            </div>
            <div
              class="text-center mt-8 w-1/2 m-auto"
              :style="{ margin: 'auto' }"
            >
              <button
                @click="sendVerification()"
                style="background: var(--activeTextColor)"
                class="senverif color_bg_primary text-white active:bg-blueGray-600 text-xs font-bold uppercase px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button"
                :class="[
                  validDataAdded ? 'is-success' : 'is-white is-outlined',
                ]"
              >
                {{ isLoading ? "Please wait..." : "Verify" }}
              </button>
            </div>
          </div>
        </div>
        <div class="relative p-6 mt-4 flex-auto w-full">
          <div class="flex justify-end px-4 py-10 pt-0">
            <button
              class="text-xs font-semibold border-b p-0 self-end outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
              type="button"
              style="color: var(--activeTextColor); border-color: currentColor"
              @click="() => $router.push('/auth/login')"
            >
              <i class="fas fa-long-arrow-alt-left"></i> Go back to login page
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import constant from "../../config";
import { useRoute } from "vue-router";
import api from "../../api/services";
import URLS from "../../config";
import zulipInit from "zulip-js";
export default {
  data() {
    return {
      newpassword: "",
      resetemail: "",
      isLoading: false,
      pass_showHide: false,
      auth: {
        userName: this.userName || "",
        password: this.password || "",
      },
      token: "",
      validUserData: true,
      isVerified: false,
      isValidPassword: false,
      errMsz: [],
    };
  },
  methods: {
    async loginToZulip(userDetils) {
      const config = {
        username: userDetils.userName,
        password: userDetils.password,
        realm: URLS.HOST_URL.ZULIP_REALM,
      };
      const zulip = await zulipInit(config);
      if (zulip.config) {
        //set detail to local storage
        let zdetails = {
          username: zulip.config.username,
          apiKey: zulip.config.apiKey,
        };
        this.$serviceHelpers.setDetails("zulip_details", zdetails);
      }
    },
    checkposswordvalid(password){
      const regex = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d]{8,20}$").test(password);
      if (regex) {
        this.isValid = false
        this.isValidPassword = true;
        this.errMsz=[];
      }else {
        this.isValid = true
        this.isValidPassword = false;
        this.errMsz['newpassword'] = "The password should be minimum of 8 characters that includes a combination of lowercase, uppercase letters & numbers without special characters"
      }
    },
    async sendVerification() {
      if (this.auth.userName == "" || this.auth.userName == null) {
        this.$toast.error("Please Enter Email");
      } else {
        if (this.apiCalled) {
        } else {
          this.apiCalled = true;
          try {
            let result = await axios.get(
              constant.HOST_URL.APOLLO_UTIL_URL +
                "password/resetlink?username=" +
                this.auth.userName
            );

            this.response = result.Paylod;
            console.log(result.Paylod);
            // this.isVerified = true;
            // console.log(username);
            this.$toast.success("A request has been received on your email.");
            // this.isLoading = false;
          } catch (error) {
            if (error.response && error.response.status == 400) {
              console.error("______verify_error", error.response.status);
              this.$toast.error(
                `${
                  error.response.data["message"] + "  /"
                }Email not found please contact admin`
              );
            } else {
              this.$toast.error("Please enter correct mail");
            }
          }
          this.apiCalled = false;
        }
      }
    },
    async username() {
      try {
        let reset_user = await api.AuthServices.validate_reset({
          reset_code: this.reset_code,
        });
        console.log("who is want to reset :--", reset_user.data);
        if (reset_user.data && reset_user.data.status == 200) {
          this.userName = reset_user.data.data.user_name;
          this.auth.userName = reset_user.data.data.user_name;
          this.isVerified = true;
        } else this.isVerified = false;
      } catch (error) {
        console.error("error in user reset : ", error);
        this.isVerified = false;
      }
    },
    async changepassword() {
      this.isLoading = true;
      try {
        if (
          (this.newpassword == "" && this.auth.userName == "") ||
          (this.newpassword == null) & (this.auth.userName == null)
        ) {
          this.$toast.error("Password or email should not be blank");
        } else {
          try {
            let result = await axios.post(
              constant.HOST_URL.APOLLO_UTIL_URL + "password/changeorreset",
              {
                password: this.newpassword,
                username: this.auth.userName,
              }
            );
            this.response = result.data;
            console.log(result.data);
            this.isLoading = false;
            this.$toast.success("Password Reset Successfully");
            this.onSubmit();
          } catch (error) {
            console.error(error);
            this.isLoading = false;
            this.$toast.error("Password Reset Unsuccessfully");
            this.$router.push("/login");
          }
        }
      } catch (error) {
        error;
      }
    },
    toggleEye() {
      this.pass_showHide = !this.pass_showHide;
    },
    async onSubmit() {
      try {
        let authData = {
          password: this.newpassword,
          userName: this.auth.userName,
        };
        if (this.isApiCalled) {
        } else {
          this.isApiCalled = true;
          let device_id = this.$serviceHelpers.uuidv4();
          // alert(device_id);

          authData.id = device_id;
          try {
            let login = await api.AuthServices.auth_login(authData);
            this.login_Response = login;
            this.isLoading = true;

            if (login.status == 200) {
              await this.loginToZulip(authData);
              this.proceedToLogin(login.data, device_id);
            } else {
              this.errcode = login.data;
              this.isLoading = false;
            }
          } catch (error) {
            console.error("Error in login", error);
            this.$toast.error("Something went wrong", {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
          this.isApiCalled = false;
        }
      } catch (error) {
        console.log("after change pass login", error);
        this.$router.push("/login");
      }
    },
    async proceedToLogin(login, device_id) {
      try {
        this.$serviceHelpers.setDetails("apollodart", login.data);
        this.$serviceHelpers.setDetails("y:a", { device_id });
        this.$serviceHelpers.setDetails("670023242:az", login.tokens);

        this.$toast.success(login.message, { position: "top" });
        this.isLoading = false;
        this.$router.push("/app");
        return true;
      } catch (error) {
        this.$router.push("/login");
        return false;
      }
    },
  },
  mounted() {
    this.isVerified = false;
    const route = useRoute();
    this.reset_code = route.params.reset_code;
    if (this.reset_code && this.reset_code != "reset") this.username();
  },
  computed: {
    validDataAdded: function () {
      return this.newpassword && this.newpassword.length > 0;
    },
  },
  watch: {
    newpassword(val) {
      this.checkposswordvalid(val);
    },
  },
};
</script>
<style scoped>
.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) #479669;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.bg_login {
  /* background: #0f1319; */
  background: var(--navBar2Bgsame);
  color: var(--textColor);
}

.login_logo {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.button {
  background: #5aba81;
}

.sign_in_card {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.curser {
  cursor: pointer;
}

.show_hide {
  position: absolute;
  right: 22px;
  top: 33px;
  cursor: pointer;
  color: var(--textColor);
}

.ipField {
  border-color: var(--activeTextColor);
}

.eye__icon__span {
  cursor: pointer;
  position: absolute;
  margin-left: -25px;
  margin-top: 13px;
}

.eye__icon {
  color: rgb(0, 0, 0);
}
</style>
