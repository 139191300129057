module.exports = [
    {
        width: 120,
        height: 45,
        y: 7,
        x: 8,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 1,
        active: false,
        class: "box-shaddow",
        name: "numeric",
        isWidget: true,
        title: 'dmea',
        titleDisplay: true,
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
    {
        width: 120,
        height: 45,
        y: 62,
        x: 8,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 1,
        active: false,
        class: "box-shaddow",
        name: "numeric",
        isWidget: true,
        titleDisplay: true,
        title: 'dbtm',
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
    {
        width: 120,
        height: 45,
        y: 117,
        x: 7,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 2,
        active: false,
        class: "box-shaddow",
        name: "numeric",
        isWidget: true,
        title: 'ropa',
        titleDisplay: true,
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
    {
        width: 120,
        height: 45,
        y: 171,
        x: 8,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 3,
        active: false,
        class: "box-shaddow",
        name: "numeric",
        title: 'sinc_157',
        isWidget: true,
        titleDisplay: true,
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
    {
        width: 120,
        height: 45,
        y: 226,
        x: 9,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 5,
        active: false,
        class: "box-shaddow",
        name: "numeric",
        title: 'sazc_157',
        isWidget: true,
        titleDisplay: true,
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
    {
        width: 120,
        height: 45,
        y: 281,
        x: 9,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        borderDisplay: false,
        zIndex: 5,
        active: false,
        class: "box-shaddow",
        name: "numeric",
        title: 'sgtf_157',
        isWidget: true,
        titleDisplay: true,
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
    {
        width: 601,
        height: 323,
        y: 11,
        x: 137,
        draggable: true,
        resizable: true,
        minw: 10,
        minh: 10,
        axis: "both",
        parentLim: true,
        snapToGrid: false,
        zIndex: 3,
        active: false,
        class: "box-shaddow",
        name: "trend",
        isWidget: true,
        titleDisplay: true,
        valueDisplay: true,
        unitDisplay: true,
        autoFit: true,
    },
]