import {
  LEFT_SIDE_BAR,
} from "./mutation-types";




export default {
  [LEFT_SIDE_BAR](state) {
    state.leftSideBarOpen = !state.leftSideBarOpen;
  },
};
