import { AES, enc } from "crypto-js";
import { db } from "./db";
async function openIndexedDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("apollo", 2);
    request.onupgradeneeded = function (event) {
      console.log(" creating new db ");
      const db = event.target.result;
      let objectStore = db.createObjectStore("apollo", { autoIncrement: "id" });
      objectStore.createIndex("well_id", "well_id", { unique: true });
    };

    request.onsuccess = function (event) {
      // console.log(" connection found ",request);
      const db = event.target.result;

      // Check the version
      const version = db.version;
      if (version < 2) {
        const deleteRequest = indexedDB.deleteDatabase("apollo");
        deleteRequest.onsuccess = function () {
          console.log("Old database deleted successfully");
          openIndexedDB();
        };
      }
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      const deleteRequest = indexedDB.deleteDatabase("apollo");
      deleteRequest.onsuccess = function () {
        console.log("Old database deleted successfully");
        // openIndexedDB();
      };
      reject(event.target.error);
    };
  });
}
export default {
  setDetails: (key, data) => {
    let stringToEncrypt = "";
    let enc_data = "";
    try {
      if (typeof data !== "string") stringToEncrypt = JSON.stringify(data);
      enc_data = AES.encrypt(stringToEncrypt, "encryptionKey").toString();
    } catch (error) {
      console.error("error in ", error);
    }

    localStorage.setItem(key, enc_data);
    return "OK";
  },
  getDetails: (key) => {
    try {
      let data = localStorage.getItem(key);
      const decryptedBytes = AES.decrypt(data, "encryptionKey");
      const decryptedString = decryptedBytes.toString(enc.Utf8);
      return JSON.parse(decryptedString);
    } catch (error) {
      return false;
    }

    // return data
  },
  setDetailsSession: (key, data) => {
    let stringToEncrypt = "";
    let enc_data = "";
    try {
      if (typeof data !== "string") stringToEncrypt = JSON.stringify(data);
      enc_data = AES.encrypt(stringToEncrypt, "encryptionKey").toString();
    } catch (error) {
      console.error("error in ", error);
    }

    sessionStorage.setItem(key, enc_data);
    return "OK";
  },
  getDetailsSession: (key) => {
    try {
      let data = sessionStorage.getItem(key);
      const decryptedBytes = AES.decrypt(data, "encryptionKey");
      const decryptedString = decryptedBytes.toString(enc.Utf8);
      return JSON.parse(decryptedString);
    } catch (error) {
      return false;
    }

    // return data
  },
  uuidv4: () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  getIndexDetails: async (key) => {
    try {
      return new Promise(async (resolve, reject) => {
        try {
          const wellDet = await db.well.where("well_id").equals(key).first();
          if (wellDet) {
            resolve(wellDet);
          } else {
            resolve({});
          }
        } catch (error) {
          console.log("error fetch details", error, key);
          resolve({});
        }

        // const db = await openIndexedDB();
        // const transaction = db.transaction(["apollo"], "readonly");
        // const objectStore = transaction.objectStore("apollo");
        // let index = objectStore.index("well_id");
        // // Use the getAll method on the index to retrieve all records with the desired inner key
        // let response = index.get(key);
        // response.onsuccess = function (event) {
        //   let data = event.target.result;
        //   resolve(data);
        // };

        // response.onerror = function (event) {
        //   console.error("get index error : ", error);
        //   resolve({});
        // };
        // console.log("get all well with id : ", response);
      });
    } catch (error) {
      console.error(" error in get index details : ", error);
      return {};
    }
  },
  async getUnitCategories(category) {
    try {
      const categories = await db.unitcategories
        .where("category")
        .equals(category)
        .first();
      if (categories) {
        return categories;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
};

export { openIndexedDB };
