export const ENABLE_ACTIVE = 'ENABLE_ACTIVE';
export const DISABLE_ACTIVE = 'DISABLE_ACTIVE';

export const SET_DISPLAY_ID = 'SET_DISPLAY_ID';
export const ADD_NEW_DISPLAY = 'ADD_NEW_DISPLAY';


export const ENABLE_DRAGGABLE = 'ENABLE_DRAGGABLE';
export const DISABLE_DRAGGABLE = 'DISABLE_DRAGGABLE';

export const ENABLE_RESIZABLE = 'ENABLE_RESIZABLE';
export const DISABLE_RESIZABLE = 'DISABLE_RESIZABLE';

export const ENABLE_PARENT_LIMITATION = 'ENABLE_PARENT_LIMITATION';
export const DISABLE_PARENT_LIMITATION = 'DISABLE_PARENT_LIMITATION';

export const ENABLE_SNAP_TO_GRID = 'ENABLE_SNAP_TO_GRID';
export const DISABLE_SNAP_TO_GRID = 'DISABLE_SNAP_TO_GRID';

export const ENABLE_ASPECT = 'ENABLE_ASPECT';
export const DISABLE_ASPECT = 'DISABLE_ASPECT';

export const ENABLE_X_AXIS = 'ENABLE_X_AXIS';
export const ENABLE_Y_AXIS = 'ENABLE_Y_AXIS';
export const ENABLE_BOTH_AXIS = 'ENABLE_BOTH_AXIS';
export const ENABLE_NONE_AXIS = 'ENABLE_NONE_AXIS';

export const CHANGE_ZINDEX = 'CHANGE_ZINDEX';

export const CHANGE_MINW = 'CHANGE_MINW';
export const CHANGE_MINH = 'CHANGE_MINH';

export const CHANGE_WIDTH = 'CHANGE_WIDTH';
export const CHANGE_HEIGHT = 'CHANGE_HEIGHT';
export const CHANGE_TOP = 'CHANGE_TOP';
export const CHANGE_LEFT = 'CHANGE_LEFT';
export const ADD_NEW_RECT = 'ADD_NEW_RECT';
export const TITLE_VISIBILITY = 'TITLE_VISIBILITY';
export const VALUE_VISIBILITY = 'VALUE_VISIBILITY';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const TITLE_COLOR = 'TITLE_COLOR';
export const VALUE_COLOR = 'VALUE_COLOR';
export const UNIT_COLOR = 'UNIT_COLOR';
export const CHANGE_UNIT = 'CHANGE_UNIT';

export const CHANGE_TITLE_COLOR = 'CHANGE_TITLE_COLOR';
export const CHANGE_VALUE_COLOR = 'CHANGE_VALUE_COLOR';
export const CHANGE_UNIT_COLOR = 'CHANGE_UNIT_COLOR';

export const UNIT_VISIBILITY = 'UNIT_VISIBILITY';
export const BORDER_VISIBILITY = 'BORDER_VISIBILITY';
export const DECIMALS = 'DECIMALS';
export const NUMERIC_MEMONIC = 'NUMERIC_MEMONIC';
export const NUMERIC_VALUE = 'NUMERIC_VALUE';
export const TITLE_FONT_SIZE = 'TITLE_FONT_SIZE';
export const ALERT_COLOR = 'ALERT_COLOR';
export const UPDATE_ZINDEX = 'UPDATE_ZINDEX';


// CIRCULAR GAUGE TYPES
export const GAUGE_BREAKPOINT_VALUE = 'GAUGE_BREAKPOINT_VALUE';
export const GAUGE_BREAKPOINT_COLOR = 'GAUGE_BREAKPOINT_COLOR';
export const GAUGE_BREAKPOINT_ADD = 'GAUGE_BREAKPOINT_ADD';
export const GAUGE_BREAKPOINT_REMOVE = 'GAUGE_BREAKPOINT_REMOVE';
export const HAS_BREAKPOINT_CHANGED = 'HAS_BREAKPOINT_CHANGED';

export const CHANGE_GAUGE_TITLE = 'CHANGE_GAUGE_TITLE';
export const CHANGE_GAUGE_VALUE = 'CHANGE_GAUGE_VALUE';
export const GAUGE_START_ANGLE = 'GAUGE_START_ANGLE';
export const GAUGE_END_ANGLE = 'GAUGE_END_ANGLE';
export const GAUGE_START_RANGE = 'GAUGE_START_RANGE';
export const GAUGE_END_RANGE = 'GAUGE_END_RANGE';
export const VALUE_DECIMALS = 'VALUE_DECIMALS';
export const GAUGE_BG_COLOR = 'GAUGE_BG_COLOR';
export const CHANGE_BORDER_COLOR = 'CHANGE_BORDER_COLOR';
export const CHANGE_BORDER_WIDTH = 'CHANGE_BORDER_WIDTH';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const GAUGE_INTERVAL = 'GAUGE_INTERVAL';
export const GAUGE_LABEL_FONT = 'GAUGE_LABEL_FONT';

export const GAUGE_MEMONIC = 'GAUGE_MEMONIC';
export const GAUGE_VALUE = 'GAUGE_VALUE';

export const BAR_START_RANGE = 'BAR_START_RANGE';
export const BAR_END_RANGE = 'BAR_END_RANGE';
export const BAR_INTERVAL = 'BAR_INTERVAL';


export const CHANGE_TEXT_COLOR = 'CHANGE_TEXT_COLOR';
export const CHANGE_BACKGROUND_COLOR = 'CHANGE_BACKGROUND_COLOR';
export const DIRECTIONAL_START_RANGE = 'DIRECTIONAL_START_RANGE';
export const DIRECTIONAL_END_RANGE = 'DIRECTIONAL_END_RANGE';
export const DIRECTIONAL_START_RADAR = 'DIRECTIONAL_START_RADAR';
export const DIRECTIONAL_END_RADAR = 'DIRECTIONAL_END_RADAR';
export const DIRECTIONAL_GRID = 'DIRECTIONAL_GRID';
export const LEFT_SIDE_BAR = 'LEFT_SIDE_BAR';
export const DARK_MODE = 'DARK_MODE';
export const LEFT_SIDE_DB_BAR = 'LEFT_SIDE_DB_BAR';
export const RIGT_SIDE_BAR = 'RIGT_SIDE_BAR';
export const RIGT_SIDE_BAR_WIDTH = 'RIGT_SIDE_BAR_WIDTH';

// Data Table Mutation Types

export const CHANGE_TAG = 'CHANGE_TAG';
export const CHANGE_START_RANGE = 'CHANGE_START_RANGE';
export const CHANGE_END_RANGE = 'CHANGE_END_RANGE';

export const CHANGE_COLOR = 'CHANGE_COLOR';
export const CHANGE_HEAD_COLOR = 'CHANGE_HEAD_COLOR';
export const CHANGE_HEAD_BACKGROUND_COLOR = 'CHANGE_HEAD_BACKGROUND_COLOR';
export const CHANGE_HEAD_FONT_SIZE = 'CHANGE_HEAD_FONT_SIZE';
export const AUTO_FIT = 'AUTO_FIT';
export const VALUE_FONT_SIZE = 'VALUE_FONT_SIZE';
export const UNTI_FONT_SIZE = 'UNTI_FONT_SIZE';
export const TITLE_COLOR_GAUGE = 'TITLE_COLOR_GAUGE';
export const VALUE_COLOR_GAUGE = 'VALUE_COLOR_GAUGE';

export const NEEDLE_COLOR_GAUGE = 'NEEDLE_COLOR_GAUGE';
export const SQUARE_GAUGE = 'SQUARE_GAUGE';

export const BAR_MEMONIC = 'BAR_MEMONIC';
export const BAR_VALUE = 'BAR_VALUE';

export const NUMERIC_ALARM_START = 'NUMERIC_ALARM_START';
export const NUMERIC_ALARM_END = 'NUMERIC_ALARM_END';
export const CIRCULAR_ALARM_START = 'CIRCULAR_ALARM_START';
export const CIRCULAR_ALARM_END = 'CIRCULAR_ALARM_END';
export const TOGGLE_DRAGGABLE = 'TOGGLE_DRAGGABLE';
export const BLINK_ALERT = 'BLINK_ALERT';

export const DRAGGABLE_ALL = 'DRAGGABLE_ALL';
export const RESIZABLE_ALL = 'RESIZABLE_ALL';

export const CHANGE_LOG_TYPE = 'CHANGE_LOG_TYPE'
export const RADAR_VISIBILITY = 'RADAR_VISIBILITY'
export const DIRECTIONAL_DATA_POINTS = 'DIRECTIONAL_DATA_POINTS'
export const MINIMIZE_RECT = 'MINIMIZE_RECT';
export const MAXIMIZE_WIDGET = 'MAXIMIZE_WIDGET';

export const PLOTLY_MNEMONIC_CHANGE = 'PLOTLY_MNEMONIC_CHANGE';
export const DELETE_DISPLAY = 'DELETE_DISPLAY';

// LINE GRAPH TYPES
export const LINE_GRAPH_MNEMONIC_CHANGE = 'LINE_GRAPH_MNEMONIC_CHANGE';

export const LINE_GRAPH_X_AXIS = 'LINE_GRAPH_X_AXIS'
export const LINE_GRAPH_Y_AXIS = 'LINE_GRAPH_Y_AXIS'
export const LINE_GRAPH_GRID_COLOR = 'LINE_GRAPH_GRID_COLOR'
export const LINE_GRAPH_GRID_BACKGROUND = 'LINE_GRAPH_GRID_BACKGROUND'
export const LINE_GRAPH_X_AXIS_LABEL_SIZE = 'LINE_GRAPH_X_AXIS_LABEL_SIZE'
export const LINE_GRAPH_Y_AXIS_LABEL_SIZE = 'LINE_GRAPH_Y_AXIS_LABEL_SIZE'


// LINE GRAPH TVD TYPES



export const LINE_GRAPH_TVD_X_AXIS = 'LINE_GRAPH_TVD_X_AXIS'
export const LINE_GRAPH_TVD_Y_AXIS = 'LINE_GRAPH_TVD_Y_AXIS'
export const LINE_GRAPH_TVD_GRID_COLOR = 'LINE_GRAPH_TVD_GRID_COLOR'
export const LINE_GRAPH_TVD_GRID_BACKGROUND = 'LINE_GRAPH_TVD_GRID_BACKGROUND'
export const LINE_GRAPH_TVD_X_AXIS_LABEL_SIZE = 'LINE_GRAPH_TVD_X_AXIS_LABEL_SIZE'
export const LINE_GRAPH_TVD_Y_AXIS_LABEL_SIZE = 'LINE_GRAPH_TVD_Y_AXIS_LABEL_SIZE'

/* Start Directional Difficulty*/
export const GRAPH_LABEL_COLOR = 'GRAPH_LABEL_COLOR'
export const GRAPH_COLOR = 'GRAPH_COLOR'
export const X_AXIS_LABEL_SIZE = 'X_AXIS_LABEL_SIZE'
export const Y_AXIS_LABEL_SIZE = 'Y_AXIS_LABEL_SIZE'
export const GRID_COLOR = 'GRID_COLOR'
export const GHARPH_BACKGROUND = 'GHARPH_BACKGROUND'
export const X_MANUAL_RANGE = 'X_MANUAL_RANGE'
export const X_START_RANGE = 'X_START_RANGE'
export const X_END_RANGE = 'X_END_RANGE'
export const Y_MANUAL_RANGE = 'Y_MANUAL_RANGE'
export const Y_START_RANGE = 'Y_START_RANGE'
export const Y_END_RANGE = 'Y_END_RANGE'
/* End Directional Difficulty*/
/* Start of XY axis widgets */
export const IS_SECONDARY_AXIS = 'IS_SECONDARY_AXIS'
export const AXIS_LABEL_COLOR = 'AXIS_LABEL_COLOR'
export const GRAPH_COLOR_XY_AXIS = 'GRAPH_COLOR_XY_AXIS'
export const GRID_COLOR_XY_AXIS = 'GRID_COLOR_XY_AXIS'
export const BACKGROUND_COLOR_XY_AXIS = 'BACKGROUND_COLOR_XY_AXIS'
export const XAXIS_LABEL_XY_AXIS = 'XAXIS_LABEL_XY_AXIS'
export const YAXIS_LABEL_XY_AXIS = 'YAXIS_LABEL_XY_AXIS'
export const AXIS_SECONDARY_LABEL_COLOR = 'AXIS_SECONDARY_LABEL_COLOR'
export const AXIS_SECONDARY_SCALE_COLOR = 'AXIS_SECONDARY_SCALE_COLOR'
export const AXIS_SECONDARY_LABEL_SIZE = 'AXIS_SECONDARY_LABEL_SIZE'
export const PRIMART_X_AXIS_MNEMONICS = 'PRIMART_X_AXIS_MNEMONICS'
export const PRIMART_Y_AXIS_MNEMONICS = 'PRIMART_Y_AXIS_MNEMONICS'
export const SECONDARY_Y_AXIS_MNEMONICS = 'SECONDARY_Y_AXIS_MNEMONICS'
/* End of XY axis widgets */




export default {
    DELETE_DISPLAY,
    MAXIMIZE_WIDGET,
    MINIMIZE_RECT,
    TOGGLE_DRAGGABLE,
    LEFT_SIDE_BAR,
    DARK_MODE,
    LEFT_SIDE_DB_BAR,
    RIGT_SIDE_BAR,
    RIGT_SIDE_BAR_WIDTH,
    CIRCULAR_ALARM_START,
    CIRCULAR_ALARM_END,
    NUMERIC_ALARM_END,
    NUMERIC_ALARM_START,
    BAR_MEMONIC,
    BAR_VALUE,
    NUMERIC_MEMONIC,
    NUMERIC_VALUE,
    SQUARE_GAUGE,
    NEEDLE_COLOR_GAUGE,
    VALUE_COLOR_GAUGE,
    TITLE_COLOR_GAUGE,
    ENABLE_ACTIVE,
    DISABLE_ACTIVE,
    ENABLE_DRAGGABLE,
    DISABLE_DRAGGABLE,
    ENABLE_RESIZABLE,
    DISABLE_RESIZABLE,
    ENABLE_PARENT_LIMITATION,
    DISABLE_PARENT_LIMITATION,
    ENABLE_SNAP_TO_GRID,
    DISABLE_SNAP_TO_GRID,
    ENABLE_ASPECT,
    DISABLE_ASPECT,
    ENABLE_X_AXIS,
    ENABLE_Y_AXIS,
    ENABLE_NONE_AXIS,
    ENABLE_BOTH_AXIS,
    CHANGE_ZINDEX,
    CHANGE_MINW,
    CHANGE_MINH,
    CHANGE_WIDTH,
    CHANGE_HEIGHT,
    CHANGE_TOP,
    CHANGE_LEFT,
    ADD_NEW_RECT,
    TITLE_VISIBILITY,
    VALUE_VISIBILITY,
    CHANGE_TITLE,
    UNIT_VISIBILITY,
    BORDER_VISIBILITY,
    TITLE_COLOR,
    VALUE_COLOR,
    UNIT_COLOR,
    DECIMALS,

    CHANGE_GAUGE_TITLE,
    CHANGE_GAUGE_VALUE,
    GAUGE_START_ANGLE,
    GAUGE_END_ANGLE,
    VALUE_DECIMALS,
    GAUGE_BG_COLOR,
    GAUGE_START_RANGE,
    GAUGE_END_RANGE,
    DECIMALS,
    CHANGE_TITLE_COLOR,
    CHANGE_VALUE_COLOR,
    CHANGE_UNIT_COLOR,
    CHANGE_BORDER_COLOR,
    CHANGE_BORDER_WIDTH,
    GAUGE_INTERVAL,
    GAUGE_LABEL_FONT,

    DELETE_WIDGET,

    BAR_START_RANGE,
    BAR_END_RANGE,
    BAR_INTERVAL,

    CHANGE_TEXT_COLOR,
    CHANGE_BACKGROUND_COLOR,
    DIRECTIONAL_START_RANGE,
    DIRECTIONAL_END_RANGE,
    DIRECTIONAL_START_RADAR,
    DIRECTIONAL_END_RADAR,
    DIRECTIONAL_GRID,

    DELETE_WIDGET,

    // Data Table Default Mutation Types
    CHANGE_TAG,
    CHANGE_START_RANGE,
    CHANGE_END_RANGE,
    CHANGE_COLOR,
    CHANGE_HEAD_COLOR,
    CHANGE_HEAD_BACKGROUND_COLOR,
    CHANGE_HEAD_FONT_SIZE,
    TITLE_FONT_SIZE,
    AUTO_FIT,
    VALUE_FONT_SIZE,
    UNTI_FONT_SIZE,
    GAUGE_MEMONIC,
    GAUGE_VALUE,
    ALERT_COLOR,
    BLINK_ALERT,
    DRAGGABLE_ALL,
    RESIZABLE_ALL,
    UPDATE_ZINDEX,

    GAUGE_BREAKPOINT_VALUE,
    GAUGE_BREAKPOINT_COLOR,
    GAUGE_BREAKPOINT_ADD,
    GAUGE_BREAKPOINT_REMOVE,
    HAS_BREAKPOINT_CHANGED,
    SET_DISPLAY_ID,
    CHANGE_LOG_TYPE,
    RADAR_VISIBILITY,
    DIRECTIONAL_DATA_POINTS,
    PLOTLY_MNEMONIC_CHANGE,

    // Line Graph
    LINE_GRAPH_MNEMONIC_CHANGE,
    LINE_GRAPH_X_AXIS,
    LINE_GRAPH_Y_AXIS,
    LINE_GRAPH_GRID_COLOR,
    LINE_GRAPH_GRID_BACKGROUND,
    LINE_GRAPH_X_AXIS_LABEL_SIZE,
    LINE_GRAPH_Y_AXIS_LABEL_SIZE,

    // Line Graph Tvd
    LINE_GRAPH_TVD_X_AXIS,
    LINE_GRAPH_TVD_Y_AXIS,
    LINE_GRAPH_TVD_GRID_COLOR,
    LINE_GRAPH_TVD_GRID_BACKGROUND,
    LINE_GRAPH_TVD_X_AXIS_LABEL_SIZE,
    LINE_GRAPH_TVD_Y_AXIS_LABEL_SIZE,

   /*Start Directional Difficulty*/
    GRAPH_LABEL_COLOR,
    GRAPH_COLOR,
    X_AXIS_LABEL_SIZE,
    Y_AXIS_LABEL_SIZE,
    GRID_COLOR,
    GHARPH_BACKGROUND,
    X_MANUAL_RANGE,
    X_START_RANGE,
    X_END_RANGE,
    Y_MANUAL_RANGE,
    Y_START_RANGE,
    Y_END_RANGE,
    /*End Directional Difficulty*/
    /* Start of xy axix widgets */
    IS_SECONDARY_AXIS,
    AXIS_LABEL_COLOR,
    GRAPH_COLOR_XY_AXIS,
    GRID_COLOR_XY_AXIS,
    BACKGROUND_COLOR_XY_AXIS,
    XAXIS_LABEL_XY_AXIS,
    YAXIS_LABEL_XY_AXIS,
    AXIS_SECONDARY_LABEL_COLOR,
    AXIS_SECONDARY_SCALE_COLOR,
    AXIS_SECONDARY_LABEL_SIZE,
    PRIMART_X_AXIS_MNEMONICS,
    PRIMART_Y_AXIS_MNEMONICS,
    SECONDARY_Y_AXIS_MNEMONICS
    /* Start of xy axix widgets */
}